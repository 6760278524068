import React, {Component} from "react";
import Label from "../elements/Label";
import './Equipment.css';

class Equipment extends Component {

    generalList: string[] = [
        "Kostenfreier Parkplatz",
        "WLAN",
        "Waschmaschine",
        "TV",
        "Bügeleisen",
        "Bettwäsche"
    ];

    kitchenList: string[] = [
        "Herd",
        "Backofen",
        "Spühlmaschine",
        "Kühlschrank",
        "Gefrierschrank",
        "Toaster",
        "Geschirr",
        "Besteck",
        "Pfannen und Töpfe"
    ];

    bathroomList: string[] = [
      "Dusche",
      "Bad",
      "WC",
      "Föhn"
    ];

    render() {
        return <div className={"equipment"}>
            <Label text={"Allgemein"} noMargin={true}/>
            <ul>
                {this.generalList.map(Equipment.getListItem)}
            </ul>

            <Label text={"Küche"}/>
            <ul>
                {this.kitchenList.map(Equipment.getListItem)}
            </ul>

            <Label text={"Bad"}/>
            <ul>
                {this.bathroomList.map(Equipment.getListItem)}
            </ul>
        </div>

    }

    static getListItem(listItem: string) {
        return <li key={listItem}>
            {listItem}
        </li>
    }
}

export default Equipment;