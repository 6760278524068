import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Nav, Navbar} from "react-bootstrap";
import './Navigation.css';

interface menuItem {
    link: string;
    text: string;
}

class Navigation extends Component {
    menuItems: menuItem[] = [
        {
            link: "/",
            text: "Übersicht"
        },
        {
            link: "/pictures",
            text: "Bilder"
        },
        {
            link: "/equipment",
            text: "Ausstattung"
        },
        {
            link: "/environment",
            text: "Umgebung & Freizeit"
        },
        {
            link: "/occupation",
            text: "Belegung & Preise"
        },
        {
            link: "/contact",
            text: "Kontakt & Anreise"
        }
    ];

    render() {
        return <Navbar className={"navbar"} expand="md">
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse>
                <Nav className="mr-auto">
                    {this.menuItems.map((contentEntry: menuItem) => {
                        return (
                            <Link
                                key={contentEntry.link}
                                className={window.location.pathname === contentEntry.link ? "nav-item nav-item-active" : "nav-item"}
                                to={contentEntry.link}>
                                {contentEntry.text}
                            </Link>
                        );
                    })}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    }
}

export default Navigation;