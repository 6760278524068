import React, {Component} from "react";
import './Header.css';
import {
    EmailIcon, EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

class Header extends Component {
    url = "https://www.penthouse-im-gruenen.de";
    iconSize=22;
    render() {
        return <div className={"header"}>
            <div className={"header-share"}>
                <div className={"header-share-bottom"}>
                    <EmailShareButton
                        url={this.url}
                        subject={"Penthouse im Grünen"}
                        body={"Jemand möchte die Homepage von der Ferienwohnung 'Penthouse im Grünen' in Neustadt an der Weinstrasse mit Ihnen teilen. "+this.url}
                    >
                        <EmailIcon size={this.iconSize} round={true}/>
                    </EmailShareButton>
                </div>
                <div className={"header-share-bottom"}>
                    <WhatsappShareButton url={this.url}>
                        <WhatsappIcon size={this.iconSize} round={true} />
                    </WhatsappShareButton>
                </div>
                <div className={"header-share-bottom"}>
                    <FacebookShareButton url={this.url}>
                        <FacebookIcon size={this.iconSize} round={true} />
                    </FacebookShareButton>
                </div>
                <div className={"header-share-bottom"}>
                    <TwitterShareButton url={this.url}>
                        <TwitterIcon size={24} round={true} />
                    </TwitterShareButton>
                </div>
            </div>
            <div><h1 className={"header-text"}>Penthouse im Grünen</h1></div>
            <div className={"header-subtext"}>Ferienwohnung - Neustadt an der Weinstrasse (Königsbach)</div>

        </div>

    }
}

export default Header;