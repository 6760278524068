import React, {Component} from "react";
import TextBlock from "../elements/TextBlock";
import './Home.css';

const banner = require("../../assets/logo/Banner.png");

interface ContentEntry {
    label: string;
    content: string;
}

class Home extends Component {
    content: ContentEntry[] = [
        {
            label:"Penthouse im Grünen mit Blick über Königsbach",
            content:"Diese lichtdurchflutete 3-Zimmer Ferienwohnung besticht durch ihre Helligkeit und die 3 Dachterrassen," +
                " die es Ihnen erlauben unsere vielen Sonnentage und den Ausblick in alle Himmelsrichtungen ausgiebig zu" +
                " geniessen.\n" +
                "Der wunderschöne Pfälzer Wald mit seinen zahlreichen Fahrrad- und Wanderwegen," +
                " sowie seinen unzähligen Waldwirtschaften befindet sich, ebenso wie der Pfälzer Mandelpfad," +
                " nur wenige Gehminuten entfernt. Weitere Bilder folgen nach der Renovierung Mitte April."
        },
        {
            label: "Aussenbereich",
            content: "Ihre 3 Dachterrassen, mit Blick auf den wunderschönen Pfälzer Wald und über die Rheinebene hinweg" +
                " machen diese Ferienwohnung zu etwas ganz Besonderem. Dieser Aussenbereich mit seinen insgesamt 60qm" +
                " lädt dazu ein, die Sonne zu geniessen, den Blick schweifen und die Seele baumeln zu lassen."
        },
        {
            label:"Nachbarschaft",
            content:"Die Ferienwohnung befindet sich in Neustadt an der Weinstraße, RP, Deutschland.\n" +
                "Die ruhige Wohngegend, direkt an der Grenze zwischen Königsbach und Gimmeldingen, bietet vor allem Erholung" +
                " und ein wundervolles Panorama, geprägt durch seine Weinberge und dem Pfälzer Wald.\n" +
                "\n" +
                "Von April bis Oktober findet in der Umgebung nahezu wöchentlich eines der vielen, über die Region hinaus" +
                " bekannten, Weinfeste statt.\n" +
                "\n" +
                "Eines der wohl bekanntesten Feste, das Gimmeldinger Mandelblütenfest, kann fussläufig erreicht werden. Der" +
                " dazugehörige Pfälzer Mandelpfad verläuft nur 300m von der Ferienwohnung entfernt."
        },
        {
            label:"Öffentliche Verkehrsmittel",
            content:"Die öffentlichen Verkehrsmittel erreichen Sie in lediglich 2 Gehminuten. Sie haben eine direkte Verbindung" +
                " nach Neustadt, Deidesheim, Gimmeldingen und auf die Haardt.\n" +
                "Vom Bahnhof Neustadt aus benötigen Sie nur etwa 30-45 Minuten mit der S-Bahn um Heidelberg, Landau," +
                " Mannheim, Kaiserslautern, Bad Dürkheim oder Ludwigshafen zu erreichen."
        },
        {
            label:"Zugang für Gäste",
            content:"Über das gemeinschaftliche Treppenhaus gelangen Sie zu Ihrem Penthouse. Nach Betreten Ihrer Ferienwohnung befinden" +
                " Sie sich im grossen Wohn- und Esszimmer mit offener Wohnküche. Neben der Küche befindet sich ein grosses" +
                " Tageslicht-Bad. An beiden Enden des Wohnbereiches befindet sich jeweils ein Schlafzimmer mit" +
                " Terrassenzugang."
        },
        {
            label:"Parken",
            content:"Ein kostenfreier, einsehbarer Parkplatz steht direkt am Haus zur Verfügung."
        },
        {
            label:"Stornierungen",
            content:"Kostenlose Stornierung innerhalb von 48 Stunden nach Buchung\n" +
                "Danach erhalten Sie eine Rückerstattung in Höhe von 50%, wenn Sie innerhalb von 14 Tagen vor Reisebeginn stornieren."
        },
        {
            label:"Langzeitvermietung",
            content:"Auch Langzeitvermietung ist auf Anfrage möglich."
        }
    ];

    render() {
        return <div>
            <img
                className="d-block banner"
                src={banner}
                alt={""}
            />
            {this.content.map((contentEntry: ContentEntry) => {
                return (
                    <TextBlock
                        key={contentEntry.label}
                        label={contentEntry.label}
                        content={contentEntry.content}
                    />
                );
            })}
        </div>

    }
}

export default Home;