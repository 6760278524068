import React, {Component} from "react";
import './Contact.css';
import Label from "../elements/Label";

class Contact extends Component {
    render() {
        return <div className={"contact-data"}>
            <Label text={"Kontaktdaten - Ferienwohnung"} noMargin={true}/>

            <p>Till Ganzert<br/>
                Herzogstr 46<br/>
                67435 Neustadt an der Weinstraße<br/>
            </p>
            <p>Tel: +49 (0)160 156 3047<br/>
                E-Mail:
                <a href="mailto:info@penthouse-im-gruenen.de?Subject=Buchungsanfrage">PenthouseImGruenen@gmail.com</a>
            </p>
            <br/>
            <Label text={"Adresse - Ferienwohnung"}/>
            <p>
                Penthouse im Grünen<br/>
                Herzogstrasse 46<br/>
                67435 Neustadt an der Weinstrasse<br/>
            </p>

            <iframe id="ifr-google" width="100%" height="497"
                    src="https://maps.google.com/maps?width=100%&amp;height=500&amp;hl=de&amp;q=Herzogstr%2046%2C%2067435+(Penthouse%20im%20Gr%C3%BCnen)&amp;ie=UTF8&amp;t=k&amp;z=15&amp;iwloc=B&amp;output=embed"
                    frameBorder="0" scrolling="no"/>
        </div>

    }
}

export default Contact;