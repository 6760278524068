import {Component} from "react";
import React from "react";
import './Label.css';

interface LabelProps {
    text: string;
    noMargin?: boolean;
}

class Label extends Component<LabelProps> {
    constructor(props: LabelProps){
        super(props);
    }

    render() {
        return <h2 className={this.props.noMargin?"label":"label label-margin-top"}>
            {this.props.text}
        </h2>
    }
}

export default Label;