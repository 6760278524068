import {Component} from "react";
import React from "react";
import './Footer.css';
import {Link} from "react-router-dom";

class Footer extends Component {
    render() {
        return <div className={"footer"}>
            <Link
                to={"/impressum"}>
                {"Impressum"}
            </Link>
            {"  © 2019 Till Ganzert"}
        </div>

    }
}

export default Footer;