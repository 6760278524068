import {Component} from "react";
import React from "react";
import Label from "./Label";
import './TextBlock.css';

interface TextBlockProps {
    label: string;
    content: string;
}

class TextBlock extends Component<TextBlockProps> {
    constructor(props: TextBlockProps){
        super(props);
    }

    render() {
        return <div className={"text-block"}>
            <Label text={this.props.label} noMargin={true}/>
            <div>
                {this.props.content}
            </div>
        </div>

    }
}

export default TextBlock;