import React, {Component} from "react";
import Label from "../elements/Label";
import './Environment.css';


interface ListItem {
    text: string;
    distance: string;
    link?: string,
    directions: string
}

class Environment extends Component {
    needsList: ListItem[] = [
        {
            text: "Bushaltestelle",
            distance: "100 m",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3840603,8.1583827/@49.3804749,8.1527015,1808m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Bäckerei",
            distance: "1000 m",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.376682,8.1540344/@49.3804749,8.1527015,1808m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Rewe Supermarkt",
            distance: "2,4 km",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3708965,8.1752/@49.3747193,8.1687444,3210m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Arzt",
            distance: "1,9 km",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3736531,8.1596355/@49.3775716,8.1528726,2597m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Apotheke",
            distance: "2,5 km",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3714847,8.167123/@49.3768459,8.1548063,2917m/data=!3m2!1e3!4b1!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Hauptbahnhof - Neustadt an der Weinstrasse",
            distance: "4,9 km",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Bahnhofstra%C3%9Fe+6,+67434+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/@49.3670247,8.1322317,5836m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4796489096a5423f:0xc4f92d49cbd9eca8!2m2!1d8.1407013!2d49.349873!3e0"
        },
        {
            text: "Krankenhaus - Neustadt an der Weinstrasse",
            distance: "4,9 km",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3466518,8.1499412/@49.3661457,8.1462222,5948m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        }
        // {
        //     text: "",
        //     distance: "100 km",
        //     directions: ""
        // },
    ];

    funList: ListItem[] = [
        {
            text: "Pfälzer Weinsteig",
            distance: "250 m",
            link: "https://www.deutsche-weinstrasse.de/pf%C3%A4lzerweinsteig/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3841652,8.1554839/@49.3836462,8.1560454,365m/data=!3m1!1e3!4m19!4m18!1m15!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!3m4!1m2!1d8.1569893!2d49.3844191!3s0x4796481496700feb:0x3be8f778b57219c7!3m4!1m2!1d8.1564085!2d49.3843666!3s0x479648135e182993:0xc3c29d502f86e8b1!1m0!3e2"
        },
        {
            text: "Lavendelhof Königsbach",
            distance: "250 m",
            link: "https://www.xn--lavendelhof-knigsbach-tec.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3862824,8.1585255/@49.3832567,8.1579644,946m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Pfälzer Mandelpfad",
            distance: "350 m",
            link: "https://www.mandelbluete-pfalz.de/de/mandelpfad/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3818818,8.1561766/@49.383379,8.1574248,651m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Pfälzer Wald",
            distance: "500 m",
            link: "https://www.pfaelzerwald.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3868991,8.1567169/@49.3853305,8.1575358,574m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Weinland Königsbach",
            distance: "650 m",
            link: "https://www.weinland-koenigsbach.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3871368,8.162263/@49.3832567,8.1579644,946m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Sportgaststätte Hildenbrandseck Königsbach",
            distance: "850 m",
            link: "http://www.sportheim-koenigsbach.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.382931,8.1695986/@49.3823683,8.1623317,967m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Waldgaststätte Pfalzblick",
            distance: "2,1 km",
            link: "https://waldgaststaette-pfalzblick.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3994984,8.1677287/@49.3910117,8.1592753,2752m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e2"
        },
        {
            text: "Freibad Mussbach",
            distance: "2,2 km",
            link: "https://www.schwimmbad-mussbach.de/index.php?id=4",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/F%C3%B6rdergemeinschaft+Mu%C3%9Fbacher+Schwimmbad+e.V/@49.377264,8.1512015,2917m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4796485b7b420001:0x493acc6cac1389b8!2m2!1d8.165819!2d49.370081!3e2"
        },
        {
            text: "Waldgaststätte Weinbiethaus",
            distance: "3,9 km",
            link: "https://www.pwv-gimmeldingen.de/weinbiethaus",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Weinbiet,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/@49.3776193,8.1315441,2917m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x479647c54dad1dad:0xd55be6e821b524e5!2m2!1d8.1216667!2d49.3761111!3e2"
        },
        {
            text: "Alla hopp! Spielplatz Deidesheim",
            distance: "4,2 km",
            link: "http://alla-hopp.de/19x-alla-hopp/19-alla-hopp-standorte/deidesheim/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/alla+hopp!,+B%C3%BCrgermeister-Oberhettinger-Stra%C3%9Fe,+Deidesheim,+Deutschland/@49.3945575,8.1675959,2916m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4796362b587a0039:0x30b11010829b66fa!2m2!1d8.1937541!2d49.4054214!3e2"
        },
        {
            text: "Restaurant Deidesheimer Hof",
            distance: "4,4 km",
            link: "https://www.deidesheimerhof.de/de/restaurant",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.4081624,8.1872784/@49.3949004,8.1556395,5833m/data=!3m2!1e3!4b1!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Erlebnisgarten Deidesheim - Minigolf und mehr",
            distance: "4,6 km",
            link: "https://www.deidesheim.de/de/erlebnis-pur/erlebnisgarten.html",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Erlebnisgarten+Deidesheim,+Deidesheim,+Deutschland/@49.3952871,8.1570219,5832m/data=!3m1!1e3!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x479637d22ce51fe9:0x3dcf82269cefd051!2m2!1d8.1897642!2d49.4089539!3e0"
        },
        {
            text: "Altstadt - Neustadt an der Weinstrasse",
            distance: "4,6 km",
            link: "https://www.neustadt.eu/Tourismus-Wein/Sehenswertes",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+67435+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3533313,8.1387887/@49.3676802,8.1395409,5836m/data=!3m2!1e3!4b1!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Fahrt mit dem Kuckucksbähnel",
            distance: "4,9 km",
            link: "http://www.eisenbahnmuseum-neustadt.de/index.html",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Bahnhofstra%C3%9Fe+6,+67434+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/@49.3670247,8.1322317,5836m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4796489096a5423f:0xc4f92d49cbd9eca8!2m2!1d8.1407013!2d49.349873!3e0"
        },
        {
            text: "Cineplex - Neustadt an der Weinstrasse",
            distance: "7 km",
            link: "https://www.cineplex.de/neustadt/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Cineplex+Neustadt,+Louis-Escande-Stra%C3%9Fe,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/@49.3623419,8.1523167,5837m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x479648de0e906e2d:0xf8559619ac6d6b42!2m2!1d8.1696771!2d49.3402109!3e0"
        },
        {
            text: "Hambacher Schloss",
            distance: "8,7 km",
            link: "https://www.neustadt.eu/B%C3%BCrger-Leben/Kultur-Veranstaltungen/Hambacher-Schloss",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3271382,8.1185907/@49.3550906,8.1032823,11675m/data=!3m2!1e3!4b1!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e2"
        },
        {
            text: "Badepark Haßloch",
            distance: "9,8 km",
            link: "https://badepark.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/49.3497153,8.2481085/@49.3515958,8.2470151,664m/data=!3m1!1e3!4m9!4m8!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m0!3e0"
        },
        {
            text: "Salzgrotte Maikammer",
            distance: "11 km",
            link: "https://www.sportpark-maikammer.de/startseite/salzgrotte/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Sportpark+Maikammer,+Schwimmbadstra%C3%9Fe,+Maikammer,+Deutschland/@49.3358397,8.1279943,11679m/data=!3m1!1e3!4m15!4m14!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x47964f6a688e8d63:0x8564f39a579adc76!2m2!1d8.13479!2d49.3018!3e0!5i2"
        },
        {
            text: "Salinen Bad Dürkheim",
            distance: "13 km",
            link: "https://www.bad-duerkheim.com/sehenswuerdigkeiten/gradierbau.html",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Salinen%2FGradierbau,+Salinenstra%C3%9Fe,+Bad+D%C3%BCrkheim,+Deutschland/@49.4235383,8.1460716,11659m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x47963742d76b346d:0xcbe6b9f5eda1d944!2m2!1d8.1745503!2d49.4655325!3e0"
        },
        {
            text: "Dürkheimer Riesenfass",
            distance: "15 km",
            link: " https://www.duerkheimer-fass.de/cms/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/D%C3%BCrkheimer+Fass,+Bad+D%C3%BCrkheim,+Sankt-Michaels-Allee,+Bad+D%C3%BCrkheim,+Deutschland/@49.4245947,8.1460716,11658m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4796375d90216945:0x9982ebd9a8381da6!2m2!1d8.1692067!2d49.464888!3e0"
        },
        {
            text: "Kurpfalz-Park Wachenheim",
            distance: "15 km",
            link: "https://www.kurpfalz-park.de/de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Kurpfalz-Park+Wachenheim,+Wachenheim+an+der+Weinstra%C3%9Fe,+Deutschland/@49.3982574,8.1126992,11665m/data=!3m1!1e3!4m15!4m14!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4796387e593d05a9:0xc15628806a60ed9c!2m2!1d8.1070626!2d49.4165146!3e0!5i1"
        },
        {
            text: "Holiday Park Pfalz",
            distance: "18 km",
            link: "https://www.holidaypark.de/de",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Holiday+Park,+Ha%C3%9Floch,+Deutschland/@49.3451244,8.2006465,11677m/data=!3m1!1e3!4m15!4m14!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x47964a4826468f6d:0x92babcf3e1ef6e8e!2m2!1d8.2979392!2d49.3183189!3e0!5i1"
        },
        {
            text: "Landauer Zoo",
            distance: "26 km",
            link: "https://www.zoo-landau.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Zoo+Landau+in+der+Pfalz,+Hindenburgstra%C3%9Fe+12,+76829+Landau+in+der+Pfalz,+Deutschland/@49.2942338,8.0824495,23379m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x479650f55f14f5bb:0x62502a780285c1aa!2m2!1d8.1116897!2d49.203869!3e0"
        },
        {
            text: "Reptilium Landau",
            distance: "27 km",
            link: "https://www.reptilium-landau.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Reptilium+Terrarien-+und+W%C3%BCstenzoo+GmbH,+Werner-Heisenberg-Stra%C3%9Fe+1,+76829+Landau+in+der+Pfalz,+Deutschland/@49.2811507,8.0998378,26167m/data=!3m1!1e3!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x479650e2c88361cd:0x5c807383d303adf7!2m2!1d8.1303032!2d49.1904168!3e0"
        },
        {
            text: "La Ola Freizeitbad Landau",
            distance: "27 km",
            link: "https://www.la-ola.de/startseite/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/La+Ola,+Horstring,+Landau+in+der+Pfalz,+Deutschland/@49.2886261,8.097083,23381m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x479651aa4699e93b:0x2b9cb8c16523c25c!2m2!1d8.1404686!2d49.2024517!3e0"
        },
        {
            text: "Dom zu Speyer",
            distance: "31 km",
            link: "https://www.dom-zu-speyer.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Dom+zu+Speyer,+Domplatz,+Speyer,+Deutschland/@49.3552673,8.2311258,23350m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4797b6bb5990252b:0xa7ebac1661fc97de!2m2!1d8.4422315!2d49.3171989!3e0"
        },
        {
            text: "Technik Museum Speyer",
            distance: "31 km",
            link: "https://speyer.technik-museum.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Technikmuseum+Speyer,+Am+Technik+Museum,+Speyer,+Deutschland/@49.3737029,8.2331886,23341m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4797b6b84fb1db1b:0xba5ad9e4c9b08e28!2m2!1d8.4471718!2d49.3114076!3e0"
        },
        {
            text: "SEA LIFE Speyer",
            distance: "31 km",
            link: "https://www.visitsealife.com/de/speyer/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/SEA+LIFE+Speyer,+Im+Hafenbecken,+Speyer,+Deutschland/@49.3552673,8.2333339,23350m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4797b6be42d208bd:0x5de8b7f76bb751d0!2m2!1d8.44741!2d49.3188451!3e0"
        },
        {
            text: "Louisenpark Mannheim",
            distance: "35 km",
            link: "https://www.luisenpark.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Luisenpark+Eingang+Unterer+Luisenpark,+68165+Mannheim,+Deutschland/@49.4259328,8.2536096,23316m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4797ce99f4e9dd59:0xeb2b7f9eb910333!2m2!1d8.4882219!2d49.4868293!3e0"
        },
        {
            text: "SAP Arena Mannheim",
            distance: "38 km",
            link: "https://saparena.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/SAP+Arena,+Mannheim,+Deutschland/@49.4069992,8.2187749,46650m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4797ced5cf4a6e2f:0x65d65b2484f79439!2m2!1d8.518961!2d49.463337!3e0"
        },
        {
            text: "Planetarium Mannheim",
            distance: "41 km",
            link: "https://www.planetarium-mannheim.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Planetarium+Mannheim,+Wilhelm-Varnholt-Allee,+Mannheim,+Deutschland/@49.4069992,8.2187749,46650m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4797cebc1c66739d:0xaf47d5b24d5b3c0d!2m2!1d8.4930371!2d49.4773238!3e0"
        },
        {
            text: "Landesgartenschau Kaiserslauter",
            distance: "42 km",
            link: "https://www.gartenschau-kl.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Gartenschau+Kaiserslautern,+Lauterstra%C3%9Fe+51,+67659+Kaiserslautern,+Deutschland/@49.4529109,7.8367852,46606m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x479612a102f61a37:0x6956921339c72781!2m2!1d7.7514457!2d49.4471904!3e0"
        },
        {
            text: "Heidelberger Schloss",
            distance: "55 km",
            link: "https://www.schloss-heidelberg.de/start/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Schloss+Heidelberg,+Schlosshof+1,+69117+Heidelberg,+Deutschland/@49.4435524,8.2970374,46615m/data=!3m1!1e3!4m15!4m14!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4797c100ca43db93:0x6d672e3649e97eea!2m2!1d8.7153092!2d49.4106196!3e0!5i1"
        },
        {
            text: "Zoo Karlsruhe",
            distance: "59 km",
            link: "https://www.karlsruhe.de/b3/freizeit/zoo.de",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Zoologischer+Stadtgarten,+Ettlinger+Str.+6,+76137+Karlsruhe,+Deutschland/@49.1873255,8.0691602,83637m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4797064678d0b7bb:0x35ab7b20579f8430!2m2!1d8.4006052!2d48.9984326!3e0"
        },
        {
            text: "Therme & Badewelt Sinsheim",
            distance: "73 km",
            link: "https://www.badewelt-sinsheim.de/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Badewelt+Sinsheim,+Badewelt,+Sinsheim,+Deutschland/@49.2941396,8.3841637,46757m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x47979198e32ad997:0xeee0b13ae4958fe0!2m2!1d8.8842105!2d49.2365614!3e0"
        },
        {
            text: "Zweibrücken Fashion Outlet",
            distance: "83 km",
            link: "https://zweibrueckenfashionoutlet.com/",
            directions: "https://www.google.com/maps/dir/Herzogstra%C3%9Fe+46,+Neustadt+an+der+Weinstra%C3%9Fe,+Deutschland/Zweibr%C3%BCcken+Fashion+Outlet,+Londoner+Bogen,+Zweibr%C3%BCcken,+Deutschland/@49.3346852,7.442144,83387m/data=!3m2!1e3!4b1!4m14!4m13!1m5!1m1!1s0x47964814e7aed795:0x8bded89b4fa47a42!2m2!1d8.158931!2d49.3843154!1m5!1m1!1s0x4795d6c0a71a0861:0x20471251ff430527!2m2!1d7.4053696!2d49.2307052!3e0"
        }
        // {
        //     text: "",
        //     distance: "650 km",
        //     link: "",
        //     directions: ""
        // },

    ];

    static getListItem(listItem: ListItem) {
        return <li key={listItem.text}>
            {listItem.link && <a className={"list-item-link"} href={listItem.link} target="_blank">
                {listItem.text}
            </a>}
            {!listItem.link && <span>
                {listItem.text}
            </span>}
            {' (' + listItem.distance + ')'}
            {listItem.directions !== null && <a className={"map-link"} href={listItem.directions} target="_blank">
                {"zur Karte"}
            </a>}
        </li>
    }

    render() {
        return <div className={"environment"}>
            <Label text={"Nützliches"} noMargin={true}/>
            <ul>
                {
                    this.needsList.map(Environment.getListItem)
                }
            </ul>

            <Label text={"Freizeit Ideen"}/>
            <ul>
                <li>
                    <a href="https://www.pfalz.de/de/pfalz-geniessen/weinfeste/weinfestkalender" target="_blank">Weinfest
                        - Kalender</a>
                </li>
                <li>
                    <a href="https://www.pfalz.de/de/veranstaltungskalender-0?search=&region=72&date%5Bmin%5D=&date%5Bmax%5D=" target="_blank">Veranstaltungs - Kalender</a>
                </li>
                {
                    this.funList.map(Environment.getListItem)
                }
            </ul>
            {"Die meisten Ziele sind auch schnell und bequem mit den "}<a href="https://www.bahn.de/p/view/index.shtml"
                                                                          target="_blank">öffentlichen
            Verkehrsmitteln</a>{" erreichbar."}
        </div>

    }
}

export default Environment;