import React, {Component} from "react";
import './Occupation.css';
import Label from "../elements/Label";
import TextBlock from "../elements/TextBlock";

class Occupation extends Component {
    render() {
        return <div>
            <TextBlock
                label={"Preise"}
                content={"Werktag: 75 Euro (2 Personen / Nacht)\n" +
                "Wochenende: 95 Euro (2 Personen / Nacht)\n" +
                "\n" +
                "Weitere Person pro Nacht: 10 Euro\n" +
                "zzgl. Endreinigung: 50 Euro"}
            />

            <iframe className={"calendar"}
                src="https://calendar.google.com/calendar/b/1/embed?showTitle=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=penthouseimgruenen%40gmail.com&amp;color=%23711616&amp;ctz=Europe%2FBerlin"
                width="100%" height="600" frameBorder="0" scrolling="no"/>
        </div>

    }
}

export default Occupation;