import React, {Component} from 'react';
import './App.css';
import {Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import Home from "./components/pages/Home";
import Pictures from "./components/pages/Pictures";
import Navigation from "./components/structure/Navigation";
import {Col} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Footer from "./components/structure/Footer";
import Header from "./components/structure/Header";
import Occupation from "./components/pages/Occupation";
import Contact from "./components/pages/Contact";
import Environment from "./components/pages/Environment";
import Impressum from "./components/pages/Impressum";
import Equipment from "./components/pages/Equipment";

class App extends Component {

render() {
        return <div style={{marginTop: "50px", marginBottom: "50px"}}>
            <Row>
                <Col xl={3} lg={2} md={1} sm={1} xs={0}/>
                <Col xl={6} lg={8} md={10} sm={10} xs={12} className={"content-box"}>
                    <Header/>
                    <Navigation/>
                    <div className={"switch"}>
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            <Route exact path="/index.html" component={Home}/>
                            <Route exact path="/pictures" component={Pictures}/>
                            <Route exact path="/equipment" component={Equipment}/>
                            <Route exact path="/environment" component={Environment}/>
                            <Route exact path="/occupation" component={Occupation}/>
                            <Route exact path="/contact" component={Contact}/>
                            <Route exact path="/impressum" component={Impressum}/>
                        </Switch>
                    </div>
                    <Footer/>
                </Col>
                <Col xl={3} lg={2} md={1} sm={1} xs={0}/>
            </Row>
        </div>
    }
}

export default App;
