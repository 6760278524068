import React, {Component} from "react";
import Carousel from "react-bootstrap/Carousel";
import './Pictures.css';
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import Label from "../elements/Label";

const pic_terrasse_1 = require("../../assets/building/terrasse_1.jpeg");
const pic_terrasse_2 = require("../../assets/building/terrasse_2.jpeg");
const pic_terrasse_3 = require("../../assets/building/terrasse_3.jpeg");
const pic_wohnzimmer_1 = require("../../assets/building/wohnzimmer_1.jpeg");
const pic_kueche_1 = require("../../assets/building/kueche_1.jpeg");
const pic_schlafzimmer_1 = require("../../assets/building/schlafzimmer_1.jpeg");
const pic_bad_1 = require("../../assets/building/bad_1.jpeg");
const pic_flur_1 = require("../../assets/building/flur_1.jpeg");
const pic_aussen_1 = require("../../assets/building/aussen_1.jpeg");

interface PicturesState {
    currentPictureIndex: number;
}

interface Picture {
    picture: string;
    textShort: string;
    textLong?: string;
}

class Pictures extends Component<any, PicturesState> {
    constructor(props: any) {
        super(props);
        this.state = {currentPictureIndex: 0};
    }

    pictures: Picture[] = [
        {
            picture: pic_terrasse_1,
            textShort: "Terrasse",
            textLong: "Terrasse mit Blick auf den Pfälzer Wald und die Rheinebene"
        },
        {
            picture: pic_terrasse_2,
            textShort: "Terrasse",
            textLong: "Terrasse mit Blick auf den Pfälzer Wald und die Rheinebene"
        },
        {
            picture: pic_terrasse_3,
            textShort: "Terrasse",
            textLong: "Terrasse mit Blick auf die umliegenden Gärten"
        },
        {
            picture: pic_wohnzimmer_1,
            textShort: "Wohnzimmer"
        },
        {
            picture: pic_kueche_1,
            textShort: "Küche"
        },
        {
            picture: pic_schlafzimmer_1,
            textShort: "Schlafzimmer 1"
        },
        {
            picture: pic_bad_1,
            textShort: "Schlafzimmer 2"
        },
        {
            picture: pic_flur_1,
            textShort: "Flur"
        },
        {
            picture: pic_aussen_1,
            textShort: "Aussenansicht"
        }
    ];

    // slideEnd(eventKey: any) {(eventKey: any, direction: 'prev' | 'next', event: object)
    //         this.setState(() => ({currentPictureIndex: eventKey}))
    // }

    render() {
        return <div className={"carousel"}>
            <Carousel
                onSelect={(eventKey: any, direction: 'prev' | 'next', event: object) => {
                    this.setState(() => ({currentPictureIndex: eventKey}))
                }}
                interval={0}
                activeIndex={this.state.currentPictureIndex}
            >
                {this.pictures.map((picture: Picture) => {
                    return (<Carousel.Item key={picture.textShort}>
                            <img
                                className="d-block w-100"
                                src={picture.picture}
                                alt={picture.textShort}
                            />
                        </Carousel.Item>
                    );
                })}
            </Carousel>
            <div className={"title"}>
                <Label noMargin={true} text={this.pictures[this.state.currentPictureIndex].textLong ?
                    this.pictures[this.state.currentPictureIndex].textLong!! :
                    this.pictures[this.state.currentPictureIndex].textShort}/>
            </div>
            <Row>
                {this.pictures.map((picture: Picture, index: number) => {
                    return (<Col key={"small" + picture.textShort} xs={3} sm={3} md={2}>
                            <img
                                className={this.state.currentPictureIndex === index ? "picture-small picture-small-active" : "picture-small"}
                                src={picture.picture}
                                alt={picture.textShort}
                                onClick={
                                    () => {
                                        this.setState(() => ({currentPictureIndex: index}))
                                    }
                                }
                            />
                            {picture.textShort}
                        </Col>
                    );
                })}
            </Row>
        </div>

    }
}

export default Pictures;